<template>
  <div class="flex-row justify-evenly orderListWrap">
    <div class="flex-col order-nav">
      <div class="order-nav-title">我的工单</div>
      <div class="flex-row items-center order-nav-item space-x-6 " :class="tabIndex == 1 ? 'order-active' : ''" @click="handleNav(1)">
        <img
          class="image_5"
          :src="require('@/assets/img/order/ic1.png')"
        />
        <span class="font_5">正在招</span>
        <div v-if="params.total1 > 0" class="flex-col justify-start items-center badge-bg"><span class="badge">{{params.total1}}</span></div>
      </div>
      <div class="flex-row items-center order-nav-item space-x-6" :class="tabIndex == 2 ? 'order-active' : ''" @click="handleNav(2)">
        <img
          class="image_5"
          :src="require('@/assets/img/order/ic2.png')"
        />
        <span class="font_5">待开工</span>
        <div v-if="params.total2 > 0" class="flex-col justify-start items-center badge-bg"><span class="badge">{{params.total2}}</span></div>
      </div>
      <div class="flex-row items-center order-nav-item space-x-6" :class="tabIndex == 3 ? 'order-active' : ''" @click="handleNav(3)">
        <img
          class="image_6"
          :src="require('@/assets/img/order/ic3.png')"
        />
        <span class="font_5">待完工</span>
        <div v-if="params.total3 > 0" class="flex-col justify-start items-center badge-bg"><span class="badge">{{params.total3}}</span></div>
      </div>
      <div class="flex-row items-center order-nav-item space-x-6" :class="tabIndex == 4 ? 'order-active' : ''" @click="handleNav(4)">
        <img
          class="image_5"
          :src="require('@/assets/img/order/ic4.png')"
        />
        <span class="font_5">已完成</span>
        <div v-if="params.total4 > 0" class="flex-col justify-start items-center badge-bg"><span class="badge">{{params.total4}}</span></div>
      </div>
      <div class="flex-row items-center order-nav-item space-x-6" :class="tabIndex == 5 ? 'order-active' : ''" @click="handleNav(5)">
        <img
          class="image_7"
          :src="require('@/assets/img/order/ic5.png')"
        />
        <span class="font_5">已结束</span>
        <div v-if="params.total5 > 0" class="flex-col justify-start items-center badge-bg"><span class="badge">{{params.total5}}</span></div>
      </div>
      <div class="flex-row items-center order-nav-item space-x-6" :class="tabIndex == 6 ? 'order-active' : ''" @click="handleNav(6)">
        <img
          class="image_5"
          :src="require('@/assets/img/order/ic6.png')"
        />
        <span class="font_5">已退款</span>
        <div v-if="params.total6 > 0" class="flex-col justify-start items-center badge-bg"><span class="badge">{{params.total6}}</span></div>
      </div>
    </div>
    <div class="flex-col order__wrap space-y-20">
      <div style="display:flex;justify-content: space-between;align-items: self-end;height:30px;">
        <span class="self-start font_4 top-title">{{toStr({1:'正在招',2:'待开工',3:'待完工',4:'已完成',5:'已结束',6:'已退款',},tabIndex)}}</span>
        <a :href="handleSkit('/created')">
          <el-button type="primary" size="mini" class="flash-hover" v-if="tabIndex == 1">发布需求</el-button>
        </a>
      </div>

      <!-- 正在招或已结束 -->
      <div class="flex-col order-main" v-if="tabIndex == 1 || tabIndex == 5">
        <div class="flex-row section_7 space-x-131">
          <div class="flex-row space-x-22">
            <span class="font_4">所需服务</span>
            <span class="font_4">服务周期</span>
          </div>
          <div class="flex-row space-x-102">
            <div class="flex-row shrink-0 space-x-24">
              <span class="font_4">定价方式</span>
              <span class="font_4">结算金额</span>
              <span class="font_4">图片</span>
              <span class="font_4" style="margin-left:40px">需求详情</span>
            </div>
            <div class="flex-row shrink-0 space-x-72">
              <span class="font_4">发布时间</span>
              <span class="font_4 text_9">报名(价)人数</span>
            </div>
            <span class="font_4" style="margin-left:100px">操作</span>
          </div>
        </div>
        <!-- 列表 -->
        <div class="orderList">
          <div class="flex-col relative order-item" v-for="(item,index) in list" :key="index">
            <div class="flex-row items-center group_4">
              <div class="flex-row items-center shrink-0">
                <el-tooltip class="item" effect="dark" :content="item.kinds_str" placement="bottom" >
                  <span class="font_6 order-title">{{item.kinds_str}}</span>
                </el-tooltip>
                <span class="font_6 text_12" style="width:178px;" v-if="!!item.pz_time">{{item.pz_time}}</span>
                <span class="font_6 text_12" style="width:178px;" v-else>{{item.start_time}} 至 {{item.end_time}}</span>
                <span class="font_6 text_13 price-type">{{toStr({1:'自主定价',2:'对方报价',3:'平台专享价'},item.price_type)}}</span>
                <div class="font_6 text_14">
                  <div v-if="item.price_type == 1 || item.price_type == 3">
                    <span v-if="item.use_num > 1">{{item.amount}}元/人</span>
                    <span v-else>{{item.amount}}元</span>
                  </div>
                  <div v-else>/</div>
                </div>
                <span class="font_6 text_15">
                  <el-image
                    v-if="item.image.length > 0"
                    style="width:20px;height:18px;"
                    :src="require('@/assets/img/order/img.jpg')"
                    :preview-src-list="item.image">
                    <i class="el-icon-picture-outline" style="color:#1d7bfc"></i>
                  </el-image>
                  <span v-else style="width:20px;display:block;text-align: center;">/</span>
                </span>
                <el-tooltip class="item" effect="dark" :content="item.content" placement="bottom">
                  <span class="font_7 text_16 order-detail1">{{item.content}}</span>
                </el-tooltip>
                <span class="font_6 text_18">{{item.create_time}}</span>
              </div>
              <el-popover
                style="padding:0 !important;"
                placement="bottom"
                title=""
                width="332px"
                trigger="click">
                <div v-if="item.apply && item.apply.length > 0" style="padding-bottom: 20px;">
                  <div class="pop-title">当前已有{{item.apply.length}}人报名，可选择施工师傅</div>
                  <div class="pop-content">
                    <div class="pop-list" v-for="(v,i) in item.apply" :key="i" @click="handleApply(v)">
                      <div style="display:flex;align-items:center;">
                        <img style="width:32px;height:32px;border-radius:50%;" :src="v.avatar" />
                        <span class="name">{{v.name}}</span>
                      </div>
                      <div class="time">{{v.create_time}}报名</div>
                      <div class="amount">报价金额：¥{{v.amount}}</div>
                    </div>
                  </div>
                </div>
                <div class="pop-title" v-else>
                  暂时无人报名，请多刷新，增加曝光率~
                </div>
                <div class="flex-row items-center shrink-0 group_5 space-x-5" slot="reference">
                  <span class="font_8 text_16 text_19" :class="item.read_count > 0 ? 'colRed' : ''">{{item.apply.length}}</span>
                  <div v-if="item.read_count > 0" class="flex-col justify-start items-center shrink-0 text-wrapper_3"><span class="font_3">{{item.read_count}}</span></div>
                </div>
              </el-popover>

              <div class="group_6 view_1">
                <template v-if="tabIndex == 1">
                  <!-- 
                    在招工状态且未支付状态且是平台专享价时，才有支付操作
                    is_pay 0:未支付，1：支付成功， 2：支付中
                    price_type 1：自主定价，2：对方报价，3：平台专享价
                   -->
                  <template v-if="item.is_pay == 0 && item.price_type == 3">
                    <span class="font_9" @click="handlePay(item)" >支付</span>
                    <span class="font_10 text_20">|</span>
                  </template>
                  <!-- <span class="font_9" @click="handleRefresh(item)">刷新</span> -->
                  <span class="font_9" @click="handleFinish(item, 1)">结束需求</span>
                </template>
                <template v-if="tabIndex ==5">
                  <!-- address_id：0 时是pc端发布的，不等于0时是移动端发布的，移动端发布的要在PC端编辑-->
                  <span class="font_9" v-if="item.address_id == 0" @click="handleFinish(item, 4)">恢复需求</span>
                  <span class="font_9" v-else @click="showEdit(item)">编辑</span>
                  <span class="font_10 text_20">|</span>
                  <span class="font_9" @click="handleDel(item)">删除</span>
                </template>
                <span class="font_10 text_20">|</span>
                <span class="font_9" @click="handleSchedule(item, 2)">工单进度</span>
              </div>
            </div>
            <div class="flex-col section_10 space-y-19">
              <span class="self-start font_11">服务地址</span>
              <div class="flex-row space-x-56">
                <span class="font_6">{{item.pz_serve == 2 ? '就诊人：' : '联系人：'}}{{item.name}}</span>
                <span class="font_6">手机号码：{{item.phone}}</span>
                <!-- <span class="font_7">服务地址：{{item.province_str}}{{item.city_str}}{{item.area_str}}</span> -->
                <el-tooltip :content="item.address" placement="bottom" effect="light">
                  <span class="font_6 address">{{item.pz_serve == 2 ? '医院：' : '服务地址：'}}{{item.address}}</span>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 待开工、待完成、已完成、已退款 -->
      <div class="flex-col order-main" v-if="tabIndex == 2 || tabIndex == 3 || tabIndex == 4 || tabIndex == 6">
        <div class="flex-row section_7 space-x-131">
          <div class="flex-row space-x-23" style="width: 100%;display: flex;justify-content: space-between;">
            <div>
              <span class="font_4">工单编号</span>
              <span class="font_4" style="margin-left:100px">所需服务</span>
              <span class="font_4" style="margin-left:50px">需求详情</span>
              <span class="font_4" style="margin-left:90px">图片</span>
              <span class="font_4" style="margin-left:30px">服务周期</span>
              <span class="font_4" style="margin-left:110px">施工师傅</span>
              <span class="font_4" style="margin-left:40px;">工单金额</span>
              <span class="font_4" style="margin-left:30px;" v-if="tabIndex == 2">状态</span>
            </div>
            <span class="font_4" style="margin-left:78px">操作</span>
          </div>
        </div>
        <!-- 列表 -->
        <div class="orderList">
          <div class="flex-col relative order-item" v-for="(item,index) in list" :key="index">
            <div class="flex-row items-center group_4">
              <div class="flex-row items-center shrink-0">
                <span class="font_6 order-no">{{item.order_no}}</span>
                <el-tooltip class="item" effect="dark" :content="item.kinds_str" placement="bottom">
                  <span class="font_6 text_12 order-text1">{{item.kinds_str}}</span>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" :content="item.content" placement="bottom">
                  <span class="font_7 text_16 order-detail">{{item.content}}</span>
                </el-tooltip>
                <span class="font_6 text_15" style="margin-left:10px;width:20px">
                  <el-image
                    v-if="item.image && item.image.length > 0"
                    style="width:20px;height:18px;"
                    :src="require('@/assets/img/order/img.jpg')"
                    :preview-src-list="item.image">
                    <i class="el-icon-picture-outline" style="color:#1d7bfc"></i>
                  </el-image>
                  <span v-else>/</span>
                </span>
                <span class="font_6 text_18" style="margin-left:35px;width:178px;" v-if="!!item.pz_time">{{item.pz_time}}</span>
                <span class="font_6 text_18" style="margin-left:35px;width:178px;" v-else>{{item.start_time}}至{{item.end_time}}</span>
                <span class="font_6" style="margin-left:20px">{{item.worker_name}}</span>
                <span class="font_6 text_14" style="margin-left:50px;">
                  <!-- use_num用工人数大于 1 多人模式 加 人 -->
                  <span v-if="item.use_num > 1">{{item.amount}}元/人</span>
                  <span v-else>{{item.amount}}元</span>
                </span>
                <template v-if="tabIndex == 2">
                  <el-tooltip v-if="!!item.text_value" class="item" effect="dark" :content="item.text_value" placement="bottom">
                    <span class="font_6 text_14 order-text2" style="margin-left:20px;">{{item.text_value}}</span>
                  </el-tooltip>
                  <span class="font_6 text_14" style="margin-left:20px;" v-else>正常</span>
                </template>
              </div>
              <div class="group_6 view_1" style="margin-left:0px">
                <span class="font_9" @click="handleSchedule(item,1)">工单进度</span>
                <span class="font_10 text_20"></span>
                <!-- 正常状态操作 -->
                <template v-if="tabIndex == 2 && item.text_status == 0">
                  <span class="font_9" @click="handleFinish(item, 2)">申请退款</span>
                </template>
                <!-- 对方申请取消工单 （同意、拒绝） -->
                <template v-if="item.text_status == 9">
                  <span class="font_9" @click="handleAgree(item)" >同意</span>
                  <span class="font_10 text_20"></span>
                  <span class="font_9" @click="handleFinish(item, 3)">拒绝</span>
                </template>
                <!-- 申请退款 （同意、拒绝） -->
                <template v-if="item.text_status == 1">
                  <span class="font_9" @click="handleCancel(item, 2)">取消</span>
                </template>
              </div>
            </div>
            <div class="flex-col section_10 space-y-19">
              <span class="self-start font_11">服务地址</span>
              <div class="flex-row space-x-56">
                <span class="font_6">{{item.title == '陪诊服务' ? '就诊人：' : '联系人：'}}{{item.name}}</span>
                <span class="font_6">手机号码：{{item.phone}}</span>
                <!-- <span class="font_7">服务地址：{{item.province_str}}{{item.city_str}}{{item.area_str}}</span> -->
                <el-tooltip :content="item.address" placement="bottom" effect="light">
                  <span class="font_6 address">{{item.title == '陪诊服务' ? '医院：' : '服务地址：'}}{{item.address}}</span>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.data.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="params.data.count"
        layout="total, sizes, prev, pager, next, jumper"
        :total="params.total">
      </el-pagination>

    </div>


    <!-- 师傅名片 -->
    <el-dialog
      title="工人师傅信息"
      :visible.sync="dialogVisible"
      width="640px">
      <div class="userInfo">
        <div class="hint">
          *若您认为该师傅合适，可在小程序或App上选择Ta服务，支付相应费用后，等待师傅为您服务！
        </div>
        <div class="info-wrap">
          <div style="display:flex;align-items:center;">
            <img :src="user.info.avatar" style="width:60px;height:60px;border-radius:50%;" alt="">
            <span class="user-name">{{user.info.name}}</span>
          </div>
          <div class="line">
            <div class="line-item">
              <span class="label">工龄：</span>
              <span>{{user.info.work_age}}年</span>
            </div>
            <div class="line-iem">
              <span class="label">身份类型：</span>
              <span>{{user.info.type == 1 ? '个人' : '班组'}}</span>
            </div>
          </div>
          <div class="line">
            <div class="">
              <span class="label">所在地区：</span>
              <span>{{user.info.address}}</span>
            </div>
          </div>
          <div class="line">
            <div class="">
              <span class="label">擅长工种：</span>
              <span>{{user.info.kinds_str.join("，")}}</span>
            </div>
          </div>
          <div class="line">
            <div class="">
              <span class="label">自我介绍：</span>
              <span>{{user.info.introduce}}</span>
            </div>
          </div>
          <div style="margin-top: 20px;font-size: 16px;">
            <div class="label">服务完工照片</div>
            <div class="img-wrap" v-if="user.image && user.image.length > 0">
              <div v-for="(img, imgIndex) in user.image" :key="imgIndex" style="margin-top:10px;">
                <div class="img-time">【{{img.title}}】{{img.complete_time}}</div>
                <div>
                  <el-image 
                    style="width: 120px; height: 120px;margin-right:10px;"
                    v-for="son in img.complete_image" :key="son"
                    :src="son" 
                    :preview-src-list="img.complete_image">
                  </el-image>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 结束工单 -->
    <el-dialog :title="dialogTitle[curType]" :width="curType == 1 ? '400px' : '600px'" :visible.sync="dialogFormVisible">
      <el-form :model="form" ref="elForm" :rules="rules" label-position="top">
        <!-- 结束需求 -->
        <template v-if="curType == 1">
          <el-form-item label="结束理由" prop="reason">
            <el-select v-model="form.reason" placeholder="请选择" style="width:100%;">
              <el-option
                v-for="item in reasonOpt"
                :key="item.id"
                :label="item.value"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
        <!-- 申请退款 -->
        <template v-if="curType == 2">
          <el-form-item label="退款金额：" prop="refund_amount">
            <el-input
              placeholder="请输入内容"
              v-model="form.refund_amount">
              <i slot="suffix" class="el-icon-edit"></i>
            </el-input>
            <div style="color:#E52525;line-height:20px">（对方同意后，金额会在48小时内原路退回至账户）</div>
          </el-form-item>
          <el-form-item label="退款原因" prop="reason">
            <el-select v-model="form.reason" placeholder="请选择" style="width:100%;">
              <el-option
                v-for="item in refundReasonOpt"
                :key="item.id"
                :label="item.value"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="退款说明" prop="desc">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="form.desc">
            </el-input>
          </el-form-item>
          <el-form-item label="凭证（限3张）" prop="image" style="display: flex;flex-direction: column;">
            <el-upload
              style="width:100%;"
              action=""
              :limit="3"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :http-request="httpRequestBack">
              <img style="104px;height:104px;" :src="require('@/assets/img/upload.png')" />
            </el-upload>
            <el-dialog :visible.sync="dialogVisibleImg" append-to-body>
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </el-form-item>
        </template>
        <!-- 拒绝退款申请 -->
        <template v-if="curType == 3">
          <el-form-item label="拒绝原因" prop="refuse_reason">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入内容"
              v-model="form.refuse_reason">
            </el-input>
          </el-form-item>
        </template>
        <!-- 恢复需求 -->
        <template v-if="curType == 4">
          <div style="font-size:14px;color: #E52525;margin-bottom:10px">请重新选择服务周期（工期），以保证需求正常发布</div>
          <!-- 陪诊服务：就诊时间 -->
          <el-form-item prop="pz_time" label="就诊时间" v-if="form.kinds == 190">
            <el-date-picker
              v-model="form.pz_time"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm"
              style="width:100%"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <!-- 代买药：代买时间  -->
          <el-form-item prop="pz_time" label="代买时间" v-else-if="form.kinds == 192">
            <el-date-picker
              v-model="form.pz_time"
              type="date"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd"
              style="width:100%"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <!-- 上门做饭服：服务时间 -->
          <el-form-item prop="food_time" label="服务时间" v-else-if="form.kinds == 183">
            <el-date-picker
              v-model="form.food_time"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm"
              style="width:100%"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="服务周期" prop="time" v-else>
            <el-date-picker
              v-model="form.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="finishSub">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 工单详情 -->
    <Detail ref="detail"></Detail>

    <!-- 支付 -->
    <order-pay v-if="payObj.show" ref="pay" :pay="payObj" @closePay="closePay"></order-pay>

    <!-- 编辑 -->
    <edit ref="edit" @refresh="getList" ></edit>

  </div>
</template>

<script>
import OrderPay from '@/components/OrderPay.vue'
import Detail from './Detail.vue'
import Edit from './Edit.vue'
export default {
  name: 'Section',
  components: {
    Detail,
    Edit,
    OrderPay,
  },
  data(){
    return {
      payObj: {
        show: false,
        code_url: '', // 支付二维码
        job_id: '', // 招工id
        order_no: '', // 订单编号
      },

      tabIndex: 1,
      navTitle: '正在招',
      params: {
        data: {
          page: 1,
          count: 10,
          type:1, //类型 1:正在招 2:待开工 3:待完工 4:已完成 5:已结束 6:已退款
        },
        total: 0,
        total1: 0,
        total2: 0,
        total3: 0,
        total4: 0,
        total5: 0,
        total6: 0,
      },
      list: [],
      dialogVisible: false,
      dialogVisible2: false,
      user: {
        info:{
          kinds_str:[],
        },
        image: []
      },
      reasonOpt: [],// 结束理由
      refundReasonOpt: [],// 工单取消理由
      dialogFormVisible: false,
      curId: '',
      curType: '',
      form: {
        id: '', // 工单ID
        reason:'', // 取消工单理由ID
        refund_amount:'', // 	退款金额
        desc:'', // 取消说明
        refund_image: [], // 上传凭证
        refuse_reason: '', // 对方申请退款拒绝原因
        time: [],
        start_time: '',
        end_time: '',
        price_type: '', // 3:平台专享价
        kinds: '',
        food_time:'', // 做饭时间
        pz_time: '', //陪诊时间、代取时间 、代买时间
        use_num: '', // 用工人数
      },
      rules:{
        reason: [{ required: true, message:'必选项', trigger: ['change','blur'] }],
        refund_amount: [{ required: true, message:'必填项', trigger: 'change' }],
        refuse_reason: [{ required: true, message:'必填项', trigger: 'change' }],
        time: [{ required: true, message:'必选项', trigger: 'change' }],
        desc: [{ required: true, message:'必填项', trigger: 'change' }],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      dialogImageUrl: [],
      dialogVisibleImg: false,
      fileList: [],

      dialogTitle: {
        1:'结束理由',
        2:'申请退款',
        3:'拒绝对方申请',
        4:'恢复需求'
      },
      info: {
        order: {},
        job: {}
      }
      
    }
  },
  created() {
    this.$nextTick(() => {
      this.getList();
    })
    this.getReason();
  },
  methods: {
    getList() {
      this.$http.get('/user/web/orderList', {params: this.params.data}).then(res => {
        if(res.code == 1) {
          this.list = res.data.list;
          this.params.total = res.data.total;
          this.params.total1 = res.data.total1;
          this.params.total2 = res.data.total2;
          this.params.total3 = res.data.total3;
          this.params.total4 = res.data.total4;
          this.params.total5 = res.data.total5;
          this.params.total6 = res.data.total6;
        }
      })
    },
    // pageSize 改变时会触发
    handleSizeChange(pageSize) {
      this.params.data.count = pageSize;
      this.params.data.page = 1;
      this.getList();
    },
    //页面改变时触发
    handleCurrentChange(page) {
      this.params.data.page = page;
      this.getList();
    },
    handleNav(type) {
      this.params.data.page = 1; // 重置分页
      this.params.data.count = 10;
      this.tabIndex = type;
      this.params.data.type = type;
      this.list = [];
      this.getList();
    },
    
    getReason() {
      // 获取结束需求理由
      this.$http.get('/user/flex_job/reason',{params:{}}).then(res => {
        if(res.code == 1) {
          this.reasonOpt = res.data;
        }
      })
      // 获取工单取消理由
      this.$http.get('/user/flex_refund/reason',{params:{}}).then(res => {
        if(res.code == 1) {
          this.refundReasonOpt = res.data;
        }
      })
    },
    // 结束需求 type 为1:结束工单，2：申请退款, 3:拒绝退款申请， 4：恢复需求
    handleFinish(item, type) {
      this.dialogFormVisible = true;
      this.form.reason = ''; // 清空结束理由
      this.curId = item.id;
      this.curType = type;
      this.form.refund_amount = item.amount;
      this.form.price_type = item.price_type; // 定价方式
      this.form.kinds = item.kinds;
      this.form.use_num = item.use_num;
    },
    finishSub() {
      this.$refs.elForm.validate(valid => {
        if(valid) {
          if (this.curType == 1) {  // 结束工单
            this.$http.post('user/flex_job/operation', {id:this.curId,reason:this.form.reason}).then(res => {
              if(res.code == 1) {
                this.$message.success('操作成功！')
                this.params.data.type = this.tabIndex;
                this.getList()
                this.dialogFormVisible = false;
              }
            })
          } else if (this.curType == 2) {  // 申请退款
            this.form.refund_image = [];
            if(this.fileList.length) {
              this.fileList.forEach(v => {
                this.form.refund_image.push(v.url)
              })
            }
            let _params = {
              id:this.curId,
              refund_amount: this.form.refund_amount,
              reason:this.form.reason,
              desc:this.form.desc,
              refund_image: this.form.refund_image.join("|")
            }
            this.$http.post('/user/flex_refund/apply', _params).then(res => {
              if(res.code == 1) {
                this.$message.success('操作成功！')
                this.params.data.type = this.tabIndex;
                this.getList()
                this.dialogFormVisible = false;
              }
            })
          } else if (this.curType == 3) { // 拒绝对方申请退款
            this.$http.post('/user/flex_order/cancelCheck', {id: this.curId, status: 2, refuse_reason: this.form.refuse_reason}).then(res => {
              if(res.code == 1) {
                this.$message.success('操作成功！')
                this.params.data.type = this.tabIndex;
                this.getList()
                this.dialogFormVisible = false;
              }
            })
          } else if (this.curType == 4) { // 恢复需求
            let _param = {
              id: this.curId,
              start_time: this.form.time[0],
              end_time:this.form.time[1],
              food_time:this.form.food_time,
              pz_time:this.form.pz_time,
              use_num: this.form.use_num,
              is_web: 1
            }
            this.$http.post('/user/flex_job/restore', _param).then(res => {
              if(res.code == 1) {
                // 只有平台专享价要支付
                if(this.form.price_type == 3) {
                  // 将订单参数传递给支付组件
                  this.payObj.code_url = res.data.code_url;
                  this.payObj.job_id = res.data.id; // 工单id
                  this.payObj.order_no = res.data.order_no; // 订单编号
                  this.payObj.show = true;
                  this.$message.success('操作成功！')
                  this.params.data.type = this.tabIndex;
                  this.getList()
                  this.dialogFormVisible = false;
                } else {
                  this.$message.success('操作成功！')
                  this.params.data.type = this.tabIndex;
                  this.getList()
                  this.dialogFormVisible = false;
                }
              }
            })
          }
        }
      })
    },
    // 查看师傅信息
    handleApply(val) {
      this.dialogVisible = true;
      this.$http.get('/user/flex_job/viewInfo', {params:{id:val.id}}).then(res => {
        if(res.code == 1) {
          this.user.info = res.data.user;
          this.user.image = res.data.image_list;
        }
      })
    },
    // 刷新
    handleRefresh(row) {
      this.$http.post('/user/flex_job/refresh', {id:row.id}).then(res => {
        if(res.code == 1) {
          this.$message.success('操作成功！')
        }
      })
    },
    // 申请退款
    handleRefund(row) {
      this.dialogFormVisible = true;
      this.curId = row.id;
    },

    // 同意退款
    handleAgree(row) {
      this.$confirm('请确认操作？','提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/user/flex_order/cancelCheck', {id:row.id,status:1}).then(res => {
          if(res.code == 1) {
            this.$message.success('操作成功！')
            this.params.data.type = this.tabIndex;
            this.getList()
          }
        })
      })
    },
    // 取消退款
    handleCancel(row) {
      this.$confirm('请确认操作？','提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/user/flex_refund/cancel', {id:row.id}).then(res => {
          if(res.code == 1) {
            this.$message.success('操作成功！')
            this.params.data.type = this.tabIndex;
            this.getList()
          }
        })
      })
    },
    // 工单进度
    handleSchedule(row, type) {
      let dom = this.$refs.detail;
      dom.toggle(true)
      dom.getDetail(row, type) // 状态正在招和已结束查招工详情，其他状态查工单详情
    },
    toStr(data,key) {
      return data[key]
    },
    handleSkit(url) {
      return `${url}`;
    },
    // 上传
    httpRequestBack(file) {
      let formData = new FormData();
      formData.append('file', file.file);
      this.$http.post('/common/qiniu/uploadFile', {formData, type:'upload'}).then(res => {
        if(res.code === 1) {
          let str = {
            url: res.data.all_path_url,
            name: file.file.name
          }
          this.fileList.push(str);
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleRemove(file) {
      this.fileList = [];
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisibleImg = true;
    },
    // 打开支付弹窗
    handlePay(row) {
      this.$http.post('/user/web/pay', {job_id:row.id}).then(res => {
        if(res.code == 1) {
          this.payObj.show = true;
          this.payObj.code_url = res.data.code_url; // 生成支付二维码
          this.payObj.order_no = res.data.order_no; // 订单编号
          this.payObj.job_id = row.id; // 招工id
        }
      })
      // this.payObj.code_url = row.code_url
    },
    // 关闭支付弹窗
    closePay() {
      this.payObj.show = false;
    },
    // 编辑
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 删除
    handleDel(row) {
      this.$confirm('提示','请确认删除?',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/user/web/delete', {id:row.id}).then(res => {
          if(res.code == 1) {
            this.$message.success('删除成功！')
            this.params.data.type = this.tabIndex;
            this.getList()
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
.orderListWrap {
  position: relative;
}
.badge {
  font-size: 12px;
  font-family: Microsoft YaHei;
  line-height: 15px;
  color: #ffffff;
}
.font_3 {
  font-size: 12px;
  font-family: Microsoft YaHei;
  line-height: 9px;
  color: #ffffff;
}
.order-nav {
  background-color: #fcfcfc;
  border: solid 1px #dddddd;
  border-top: none;
  width: 200px;
}
.order-nav-title {
  width: 100%;
  height: 79px;
  line-height: 79px;
  text-align: center;
  border-bottom: solid 1px #ddd;
  font-size: 20px;
  font-family: Microsoft YaHei;
  letter-spacing: 2px;
}
.order-nav-item {
  padding: 18px 0;
  background-color: #f7f7f7;
  border-bottom: solid 1px #ddd;
  cursor: pointer;
}
.order-nav-item:hover {
  background: #fff;
}
.order-active {
  background: #fff;
}
.text_7 {
  color: #333333;
  height: 45px;
  border-bottom: solid 1px #ddd;
}
.section_5 {
  margin-top: 30px;
  padding: 18px 38px;
  background-color: #ffffff;
  border: solid 1px #dddddd;
}
.image_4 {
  margin-left: 20px;
  width: 14px;
  height: 15px;
}
.font_5 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  letter-spacing: 1.5px;
  line-height: 12px;
  color: #595959;
  /* margin-left: 5px; */
}
.text_10 {
  color: #333333;
}
.text-wrapper_2 {
  padding-top: 4px;
  background-color: #e52525;
  border-radius: 8px;
  height: 16px;
}
.text_11 {
  margin-left: 4px;
}
.space-x-6 > *:not(:first-child) {
  margin-left: 6px;
}
.image_5 {
  margin-left: 58px;
  width: 15px;
  height: 15px;
}
.badge-bg {
  margin-right: 52px;
  padding-bottom: 4px;
  background-color: #e52525;
  border-radius: 8px;
  min-width: 16px;
  height: 16px;
  padding: 0 2px;
}
.image_6 {
  margin-left: 59px;
  width: 13px;
  height: 14px;
}
.image_7 {
  margin-left: 58px;
  width: 15px;
  height: 14px;
}
.order__wrap {
  padding: 28px 0 10px 20px;
  background-color: #ffffff;
  box-shadow: inset 0px -1px #dddddd;
  width: 1120px;
  position: relative;
}
.space-y-20 > *:not(:first-child) {
  margin-top: 20px;
}
.font_4 {
  font-size: 16px;
  font-family: Microsoft YaHei;
  line-height: 16px;
  font-weight: 700;
  color: #5e7da6;
}

.section_7 {
  padding: 14px 24px 18px;
  background-color: #dfeeff;
  border-top: solid 1px #1d7bfc;
}
.space-x-131 > *:not(:first-child) {
  margin-left: 131px;
}
.space-x-22 > *:not(:first-child) {
  margin-left: 22px;
}
.space-x-23 > *:not(:first-child) {
  margin-left: 26px;
}
.space-x-102 > *:not(:first-child) {
  margin-left: 98px;
}
.space-x-24 > *:not(:first-child) {
  margin-left: 24px;
}
.space-x-72 > *:not(:first-child) {
  margin-left: 72px;
}
.text_9 {
  line-height: 17px;
}

.orderList {
  height: 670px;
  overflow-y: scroll;
}
.orderList::-webkit-scrollbar {
  width: 1px;
}
.order-item {
  padding: 0 17px 24px 24px;
  background-color: #ffffff;
  box-shadow: inset 0px -1px #dddddd;
}
.order-item:hover {
  background: #F8FBFF;
}
.order-item:hover .section_10 {
  background: #fff;
  border: solid 1px #CFE4FC;
}
.group_4 {
  padding: 18px 0;
  justify-content: space-between;
}
.font_6 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  line-height: 12px;
  color: #595959;
}
.text_12 {
  margin-left: 29px;
}
.text_13 {
  margin-left: 16px;
}
.text_14 {
  margin-left: 30px;
  width: 70px;
}
.text_15 {
  line-height: 13px;
}
.font_7 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  line-height: 14px;
  color: #595959;
}
.text_18 {
  margin-left: 15px;
  line-height: 11px;
}
.font_8 {
  font-size: 16px;
  font-family: Microsoft YaHei;
  line-height: 16px;
  font-weight: 700;
  color: #595959;
}
.colRed {
  color: #e52525;
}
.text-wrapper_3 {
  padding: 4px 0;
  background-color: #e52525;
  border-radius: 8px;
  width: 16px;
  height: 16px;
}
.group_6 {
  line-height: 15px;
  height: 15px;
}
.view_1 {
  margin-left: 12px;
}
.font_9 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  line-height: 14px;
  color: #1d7bfc;
  cursor: pointer;
}
.font_9:hover {
  color: #035bd6;
}
.font_10 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  line-height: 14px;
  color: #bfbfbf;
}
.text_20 {
  line-height: 15px;
  margin: 0 4px;
}
.section_10 {
  padding: 20px 21px 25px;
  background-color: #f9f9f9;
  border: solid 1px #f9f9f9;
}
.space-y-19 > *:not(:first-child) {
  margin-top: 19px;
}
.font_11 {
  font-size: 14px;
  font-family: Microsoft YaHei;
  line-height: 12px;
  font-weight: 700;
  color: #595959;
}
.space-x-56 > *:not(:first-child) {
  margin-left: 56px;
}
.text_21 {
  line-height: 13px;
}
.text_23 {
  color: #898989;
}
.group_7 {
  padding: 20px 0 17px;
}
.font_12 {
  font-size: 16px;
  font-family: Microsoft YaHei;
  line-height: 16px;
  font-weight: 700;
  color: #595959;
}
.text_16 {
  text-decoration: underline;
}
.text_19 {
  line-height: 17px;
}
.text_17 {
  margin-left: 35px;
}
.text_25 {
  line-height: 17px;
}
.group_5 {
  margin-left: 50px;
  width: 30px;
  cursor: pointer;
}
.space-x-5 > *:not(:first-child) {
  margin-left: 5px;
}
.view_2 {
  margin-left: 73px;
}
.order-title {
  width: 60px;
  overflow:hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.order-detail {
  width: 144px;
  overflow:hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 25px;
}
.order-detail:hover {
  color: #1d7bfc;
  cursor: pointer;
  text-decoration: none;
}
.order-detail1 {
  width: 144px;
  overflow:hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 57px;
}
.order-detail1:hover {
  color: #1d7bfc;
  cursor: pointer;
  text-decoration: none;
}
.pop-title {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #595959;
  line-height: 36px;
  padding: 10px 24px 0;
}

.pop-list {
  border-top: solid 1px #E5E5E5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  cursor: pointer;
}
.pop-list:last-child {
  border-bottom: solid 1px #E5E5E5;;
}
.pop-list:hover {
  background: #F8FBFF;
  border-top: solid 1px #F8FBFF;
}
.pop-list .name {
  margin-left: 10px;
}
.address {
  width: 360px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}
.top-title {
  color: #333;
  display: contents;
}
.userInfo {

}
.user-name {
  font-size: 22px;
  font-weight: bold;
  margin-left: 10px;
}
.hint {
  background: #FFF6ED;
  font-size: 16px;
  color: #FB7C1F;
  padding: 10px 20px;
}
.info-wrap {
  margin: 20px;
}
.line {
  display: flex;
  margin-top: 20px;
  font-size: 16px;
  flex-wrap: wrap;
}
.line-item {
  width: 50%;
  height: 40px;
}
.label {
  color: #a3a3a3;
}
.img-wrap {
  background: #F8F8F8;
  padding: 0 30px 10px;
  margin-top: 20px;
  max-height: 300px;
  overflow-y: scroll;
}

.img-time {
  margin-bottom: 10px;
  margin-top: 20px;
}
.order-text1 {
  width: 90px;
  margin-left: 15px;
  overflow:hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.order-text2 {
  width: 90px;
  margin-left: 15px;
  overflow:hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
<style >
.list-pop ::v-deep .el-popover {
  padding: 0 !important;
}
.el-dialog__header {
  border-bottom: solid 1px #ddd;
}
.el-form-item {
  margin-bottom: 18px;
}
.el-upload--picture-card {
  border: none;
  width: 104px;
  height: 104px;
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 104px;
  height: 104px;
}
.pop-list .time {
  margin-left: 10px;
}
.amount {
  margin-left: 10px;
}
.price-type {
  width: 70px;
}
</style>