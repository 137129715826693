<template>
  <div>
    <Header></Header>
    <div class="main__wrap" style="width:1320px;margin:0 auto;">
      <Section></Section>
    </div>
  </div>
</template>

<script>
import Header from './header.vue'
// import Header from '../home/header.vue'
import Section from './section.vue'
export default {
  name: 'OrderName',
  components: {
    Header,
    Section
  },
  data() {
    return {}
  },
  
}
</script>

<style scoped>
.main__wrap {
  background: #F5F5F5;
}
</style>