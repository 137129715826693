<template>
  <!-- 工单进度 -->
  <el-dialog
    title="工单进度"
    :visible.sync="visible"
    width="640px"
    top="5vh">
    <!-- 工单详情 -->
    <div class="main" v-if="orderType == 1">
      <el-divider content-position="left">个人信息</el-divider>
      <div class="info-top">
        <div style="text-align:left;">
          <p>工单编号: {{dataInfo.order.order_no}}</p>
          <p>发布者：{{dataInfo.job.account_name}}</p>
          <p>联系电话：{{dataInfo.job.account_phone}}</p>
        </div>
        <el-avatar
          style="width: 80px; height: 80px;margin-left:50px"
          shape="square"
          :src="dataInfo.order.avatar"
          fit="cover">
          <img :src="require('@/assets/img/order/default.png')"/>
        </el-avatar>
      </div>
      <el-divider></el-divider>
      <el-descriptions title="招工信息" :column="1" border>
        <el-descriptions-item label="发布时间" :labelStyle='labelStyle'>{{dataInfo.job.create_time}}</el-descriptions-item>
        <el-descriptions-item label="所属工种" :labelStyle='labelStyle'>{{dataInfo.job.kinds_str}}</el-descriptions-item>
        <el-descriptions-item label="招工标题" :labelStyle='labelStyle'>{{dataInfo.job.title}}</el-descriptions-item>
        <!-- 若有规格则展示规格 -->
        <el-descriptions-item v-if="!!dataInfo.job.item_str" label="规格" :labelStyle='labelStyle'>{{dataInfo.job.item_str}} > {{dataInfo.job.spec_str}}</el-descriptions-item>
        <!-- 若有用工人数则展示人数 -->
        <el-descriptions-item v-if="dataInfo.job.use_num > 1" label="用工人数" :labelStyle='labelStyle'>{{dataInfo.job.use_num}}人</el-descriptions-item>
        
        <!-- kinds:190 陪诊服务 -->
        <template v-if="dataInfo.job.kinds === 190">
          <el-descriptions-item label="陪诊医院" :labelStyle='labelStyle'>{{dataInfo.job.pz_hospital}}</el-descriptions-item>
          <el-descriptions-item label="科室" :labelStyle='labelStyle'>{{dataInfo.job.pz_dep}}</el-descriptions-item>
          <el-descriptions-item label="就诊时间" :labelStyle='labelStyle'>{{dataInfo.job.pz_time}}</el-descriptions-item>
          <el-descriptions-item label="就 诊 人" :labelStyle='labelStyle'>{{dataInfo.job.pz_serve_name}}&nbsp;&nbsp;{{dataInfo.job.pz_serve_mobile}}&nbsp;&nbsp;{{dataInfo.job.pz_serve_relation_str}}</el-descriptions-item>
          <el-descriptions-item label="身份证号" :labelStyle='labelStyle'>{{dataInfo.job.pz_serve_id_card}}</el-descriptions-item>
          <el-descriptions-item label="需要陪诊时间" :labelStyle='labelStyle'>{{dataInfo.job.pz_duration == 1 ? '半天' : '一天'}}</el-descriptions-item>
          <el-descriptions-item label="是否需要接送" :labelStyle='labelStyle'>{{dataInfo.job.pz_serve == 1 ? '是' : '否'}}</el-descriptions-item>
          <!-- 如果需要接送要展示联系人 -->
          <el-descriptions-item label="联系人" :labelStyle='labelStyle' v-if="dataInfo.job.pz_serve == 1">{{dataInfo.job.name}}&nbsp;&nbsp;{{dataInfo.job.phone}}</el-descriptions-item>
          <el-descriptions-item label="服务地址" :labelStyle='labelStyle'>{{dataInfo.job.address}}</el-descriptions-item>
        </template>
        <template v-else>
          <el-descriptions-item label="需求详情" :labelStyle='labelStyle'>{{dataInfo.job.content}}</el-descriptions-item>
          <!-- 做饭阿姨用工时间 food_time -->
          <template v-if="dataInfo.job.kinds === 183">
            <el-descriptions-item label="用工时间" :labelStyle='labelStyle'>{{dataInfo.job.food_time}}</el-descriptions-item>
            <el-descriptions-item label="做菜数量" :labelStyle='labelStyle'>{{dataInfo.job.food_num}}</el-descriptions-item>
            <el-descriptions-item label="菜系口味" :labelStyle='labelStyle'>{{dataInfo.job.food_flavor}}</el-descriptions-item>
            <el-descriptions-item label="是否需要买菜" :labelStyle='labelStyle'>{{dataInfo.job.food_buy == 1 ? '是' : '否'}}</el-descriptions-item>
          </template>
          <el-descriptions-item v-else label="用工时间" :labelStyle='labelStyle'>{{dataInfo.job.start_time}} - {{dataInfo.job.start_time}}</el-descriptions-item>
          
          <el-descriptions-item v-if="dataInfo.job.kinds === 191" label="医院地址" :labelStyle='labelStyle'>{{dataInfo.job.address}}</el-descriptions-item>
          <el-descriptions-item v-else-if="dataInfo.job.kinds === 192" label="购买地址" :labelStyle='labelStyle'>{{dataInfo.job.address}}</el-descriptions-item>
          <el-descriptions-item v-else label="用工地址" :labelStyle='labelStyle'>{{dataInfo.job.address}}</el-descriptions-item>
        </template>
        <!-- <el-descriptions-item label="定价方式" :labelStyle='labelStyle'>{{toStr({1:'自主定价', 2:'对方报价'},dataInfo.job.price_type)}}</el-descriptions-item> -->
        <el-descriptions-item label="结算金额" :labelStyle='labelStyle'>
          <span v-if="dataInfo.job.use_num > 1">¥{{dataInfo.job.amount}}/人</span>
          <span v-else>¥{{dataInfo.job.amount}}</span>
          <span style="font-size:10px;color:#1966FF">&nbsp;&nbsp;{{toStr({1:'自主定价', 2:'对方报价',3:'平台专享价'},dataInfo.job.price_type)}}</span>
        </el-descriptions-item>
        <el-descriptions-item label="支付状态" v-if="dataInfo.job.price_type == 3">{{toStr({0:'未支付',1:'已支付',2:'支付中'},dataInfo.job.is_pay)}}</el-descriptions-item>
        <!-- <el-descriptions-item label="浏览量" :labelStyle='labelStyle'>{{dataInfo.job.browse_count || 0}}次</el-descriptions-item>
        <el-descriptions-item label="报名人数" :labelStyle='labelStyle'>{{dataInfo.job.apply_num}}人</el-descriptions-item> -->
      </el-descriptions>

      <el-descriptions title="工单信息" :column="2" border style="margin-top:20px;">
        <el-descriptions-item label="选择工人">{{dataInfo.order.name}}</el-descriptions-item>
        <el-descriptions-item label="性别">{{dataInfo.order.sex === 1 ? '男' : '女'}}</el-descriptions-item>
        <el-descriptions-item label="联系电话">{{dataInfo.order.phone}}</el-descriptions-item>
        <!-- 只有平台专享价时 不显示选择时间和工单金额 -->
        <el-descriptions-item label="接单时间" v-if="dataInfo.job.price_type == 3" >{{dataInfo.order.choose_time}}</el-descriptions-item>
        <template v-else>
          <el-descriptions-item label="报名时间">{{dataInfo.order.apply_time}}</el-descriptions-item>
          <el-descriptions-item label="选择时间">{{dataInfo.order.choose_time}}</el-descriptions-item>
          <el-descriptions-item label="工单金额">
            <span v-if="dataInfo.order.use_num > 1">¥{{dataInfo.order.total_amount}}/人</span>
            <span v-else>¥{{dataInfo.order.total_amount}}</span>
          </el-descriptions-item>
        </template>
       
        <!-- 待开工 -->
        <template v-if="dataInfo.order.order_status === 2">
          <el-descriptions-item label="申请退款时间" v-if="dataInfo.order.text_status > 0">{{dataInfo.cancel.create_time || ''}}</el-descriptions-item>
          <el-descriptions-item label="取消退款时间" v-if="dataInfo.order.text_status == 4">{{dataInfo.cancel.cancel_time || ''}}</el-descriptions-item>
          <el-descriptions-item label="拒绝时间" v-if="dataInfo.order.text_status == 10">{{dataInfo.cancel.refuse_time || ''}}</el-descriptions-item>
        </template>
        <!-- 待完工 -->
        <template v-if="dataInfo.order.order_status === 3">
          <el-descriptions-item label="开工时间">{{dataInfo.clock.create_time || ''}}</el-descriptions-item>
        </template>
        <!-- 已完成 -->
        <template v-if="dataInfo.order.order_status === 5">
          <el-descriptions-item label="开工时间">{{dataInfo.clock.create_time || ''}}</el-descriptions-item>
          <el-descriptions-item label="完工时间">{{dataInfo.order.complete_time}}</el-descriptions-item>
        </template>
        <!-- 已退款 -->
        <template v-if="dataInfo.order.order_status === 6">
          <!-- 平台介入 -->
          <template v-if="dataInfo.order.text_status == 14">
            <el-descriptions-item label="申请平台介入时间 ">{{dataInfo.cancel.create_time || ''}}</el-descriptions-item>
            <el-descriptions-item label="平台处理时间">{{dataInfo.cancel.refuse_time || ''}}</el-descriptions-item>
            <el-descriptions-item label="处理结果">{{dataInfo.intervene.desc}}</el-descriptions-item>
          </template>
          <template v-else>
            <el-descriptions-item label="申请退款时间">{{dataInfo.cancel.create_time || ''}}</el-descriptions-item>
            <el-descriptions-item label="同意退款时间">{{dataInfo.cancel.agree_time || ''}}</el-descriptions-item>
          </template>
        </template>
        <el-descriptions-item label="状态" v-if="!!dataInfo.order.text_value">
           <!-- <span>{{dataInfo.order.cancel_type ? (dataInfo.order.status > 4 ? cancel[dataInfo.order.cancel_type] : applyCancel[dataInfo.order.cancel_type]) :status[dataInfo.order.status]}}</span> -->
           <span>{{dataInfo.order.text_value}}</span>
        </el-descriptions-item>
      </el-descriptions>
       <!-- 待开工、待完工展示开工打卡图片 -->
      <template v-if="dataInfo.order.status === 2 || dataInfo.order.status === 3">
        <el-descriptions title="开工打卡图片" :column="1" :colon="false" style="margin-top:20px">
          <el-descriptions-item label="">
            <el-image
              style="width: 100px; height: 100px"
              :src="dataInfo.order.clock_image"
              fit="cover"
              :preview-src-list="[dataInfo.order.clock_image]">
                 <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
          </el-descriptions-item>
        </el-descriptions>
      </template>
      <!-- 已完成展示完工打卡图片 -->
      <template v-if="dataInfo.order.status === 5">
        <el-descriptions title="完工打卡图片" :column="1" :colon="false" style="margin-top:20px">
          <el-descriptions-item label="">
            <span v-for="img in dataInfo.order.complete_image" :key="img" style="margin-right:10px;">
              <el-image
                style="width: 100px; height: 100px"
                :src="img"
                fit="cover"
                :preview-src-list="dataInfo.order.complete_image"></el-image>
            </span>
          </el-descriptions-item>
        </el-descriptions>
      </template>
    </div>

    <!-- 招工详情 -->
    <div class="main" v-else>
      <el-divider content-position="left">个人信息</el-divider>
      <div class="info-top">
        <div style="text-align:left;">
          <p>发布者：{{dataInfo.info.account_name}}</p>
          <p>联系电话：{{dataInfo.info.account_phone}}</p>
        </div>
        <el-avatar
          style="width: 80px; height: 80px;margin-left:50px"
          shape="square"
          :src="dataInfo.info.avatar"
          fit="cover">
          <img :src="require('@/assets/img/order/default.png')"/>
        </el-avatar>
      </div>
      <el-divider></el-divider>
      <el-descriptions title="招工信息" :column="1" border>
        <el-descriptions-item label="发布时间" :labelStyle='labelStyle'>{{dataInfo.info.create_time}}</el-descriptions-item>
        <template v-if="dataInfo.info.status == 2">
          <el-descriptions-item label="結束时间" :labelStyle='labelStyle'>{{dataInfo.info.finish_time}}</el-descriptions-item>
        </template>
        <el-descriptions-item label="所属工种" :labelStyle='labelStyle'>{{dataInfo.info.parent_kinds_str}}</el-descriptions-item>
        <el-descriptions-item label="招工标题" :labelStyle='labelStyle'>{{dataInfo.info.title}}</el-descriptions-item>
        <!-- 若有规格则展示规格 -->
        <el-descriptions-item v-if="!!dataInfo.info.item_str" label="规格" :labelStyle='labelStyle'>{{dataInfo.info.item_str}} > {{dataInfo.info.spec_str}}</el-descriptions-item>
        <!-- 若有用工人数则展示人数 -->
        <el-descriptions-item v-if="dataInfo.info.use_num > 1" label="用工人数" :labelStyle='labelStyle'>{{dataInfo.info.use_num}}人</el-descriptions-item>

        <!-- kinds:190 陪诊服务 -->
        <template v-if="dataInfo.info.kinds === 190">
          <el-descriptions-item label="陪诊医院" :labelStyle='labelStyle'>{{dataInfo.info.pz_hospital}}</el-descriptions-item>
          <el-descriptions-item label="科室" :labelStyle='labelStyle'>{{dataInfo.info.pz_dep}}</el-descriptions-item>
          <el-descriptions-item label="就诊时间" :labelStyle='labelStyle'>{{dataInfo.info.pz_time}}</el-descriptions-item>
          <el-descriptions-item label="就 诊 人" :labelStyle='labelStyle'>{{dataInfo.info.pz_serve_name}}&nbsp;&nbsp;{{dataInfo.info.pz_serve_mobile}}&nbsp;&nbsp;{{dataInfo.info.pz_serve_relation_str}}</el-descriptions-item>
          <el-descriptions-item label="身份证号" :labelStyle='labelStyle'>{{dataInfo.info.pz_serve_id_card}}</el-descriptions-item>
          <el-descriptions-item label="需要陪诊时间" :labelStyle='labelStyle'>{{dataInfo.info.pz_duration == 1 ? '半天' : '一天'}}</el-descriptions-item>
          <el-descriptions-item label="是否需要接送" :labelStyle='labelStyle'>{{dataInfo.info.pz_serve == 1 ? '是' : '否'}}</el-descriptions-item>
          <!-- 如果需要接送 则展示联系人 -->
          <el-descriptions-item label="联系人" :labelStyle='labelStyle' v-if="dataInfo.info.pz_serve == 1">{{dataInfo.info.name}}&nbsp;&nbsp;{{dataInfo.info.phone}}</el-descriptions-item>
          <el-descriptions-item label="服务地址" :labelStyle='labelStyle'>{{dataInfo.info.address}}</el-descriptions-item>
        </template>
        <template v-else>
          <!-- 做饭阿姨用工时间 food_time -->
          <template v-if="dataInfo.info.kinds === 183">
            <el-descriptions-item label="用工时间" :labelStyle='labelStyle'>{{dataInfo.info.food_time}}</el-descriptions-item>
            <el-descriptions-item label="做菜数量" :labelStyle='labelStyle'>{{dataInfo.info.food_num}}</el-descriptions-item>
            <el-descriptions-item label="菜系口味" :labelStyle='labelStyle'>{{dataInfo.info.food_flavor}}</el-descriptions-item>
            <el-descriptions-item label="是否需要买菜" :labelStyle='labelStyle'>{{dataInfo.info.food_buy == 1 ? '是' : '否'}}</el-descriptions-item>
          </template>
          <el-descriptions-item v-else label="用工时间" :labelStyle='labelStyle'>{{dataInfo.info.start_time}} - {{dataInfo.info.end_time}}</el-descriptions-item>
          
          <el-descriptions-item v-if="dataInfo.info.kinds === 191" label="医院地址" :labelStyle='labelStyle'>{{dataInfo.info.address}}</el-descriptions-item>
          <el-descriptions-item v-else-if="dataInfo.info.kinds === 192" label="购买地址" :labelStyle='labelStyle'>{{dataInfo.info.address}}</el-descriptions-item>
          <el-descriptions-item v-else label="用工地址" :labelStyle='labelStyle'>{{dataInfo.info.address}}</el-descriptions-item>
          <el-descriptions-item label="联系方式" :labelStyle='labelStyle'>{{dataInfo.info.name}}&nbsp;&nbsp;{{dataInfo.info.phone}}</el-descriptions-item>
        </template>
        <el-descriptions-item label="需求详情" :labelStyle='labelStyle'>{{dataInfo.info.content}}</el-descriptions-item>
        <!-- <el-descriptions-item label="定价方式" :labelStyle='labelStyle'>{{toStr({1:'自主定价',2:'对方报价'},dataInfo.info.price_type)}}</el-descriptions-item> -->
        <el-descriptions-item label="工单金额" :labelStyle='labelStyle'>
          <template v-if="dataInfo.info.price_type == 1 || dataInfo.info.price_type == 3">
            <span v-if="dataInfo.info.use_num > 1">￥{{dataInfo.info.amount}}/人&nbsp;&nbsp;</span>
            <span v-else>￥{{dataInfo.info.amount}}&nbsp;&nbsp;</span>
            <span style="font-size:10px;color:#1966FF">{{toStr({1:'自主定价',2:'对方报价',3:'平台专享价'},dataInfo.info.price_type)}}</span>
          </template>
          <!-- 如果用户选择的是对方报价，这里展示：待报价 -->
          <template v-else>
            <span>待报价&nbsp;&nbsp;</span>
            <span style="font-size:10px;color:#1966FF">{{toStr({1:'自主定价',2:'对方报价',3:'平台专享价'},dataInfo.info.price_type)}}</span>
          </template>
        </el-descriptions-item>
        <!-- 平台专享价才有支付状态 -->
        <el-descriptions-item label="支付状态" v-if="dataInfo.info.price_type == 3">{{toStr({0:'未支付',1:'已支付',2:'支付中'},dataInfo.info.is_pay)}}</el-descriptions-item>
        <!-- <el-descriptions-item label="结算金额" :labelStyle='labelStyle'>￥{{dataInfo.info.amount}} &nbsp;&nbsp;{{settleType[dataInfo.info.settle_type]}}</el-descriptions-item> -->
        <el-descriptions-item label="浏览量" :labelStyle='labelStyle'>{{dataInfo.info.browse_count || 0}}次</el-descriptions-item>
        <el-descriptions-item label="报名人数" :labelStyle='labelStyle'>{{applyNum}}人</el-descriptions-item>
        <!--  -->
        <!-- <el-descriptions-item label="接单模式" :labelStyle='labelStyle'>{{applyNum}}人</el-descriptions-item> -->
      </el-descriptions>
      <div style="display:flex;flex-wrap: wrap;">
        <div class="block portrait" v-for="(item, i) in dataInfo.apply" :key="i">
          <el-avatar size="large" :src="item.avatar"></el-avatar>
          <div>{{item.name}}</div>
          <div>{{item.phone}}</div>
          <!-- 定价方式：对方报价，则展示报价 -->
          <div v-if="dataInfo.info.price_type == 2" style="color: #f56c6c;font-weight: bold;margin-top: 5px;">报价：{{item.amount}}元</div>
        </div>
      </div>
    </div>

  </el-dialog>
</template>

<script>
export default {
  name: 'OrderDetail',
  data() {
    return {
      visible: false,
      orderType: 1, // 1:工单详情，2：招工详情
      dataInfo: {
        cancel: {},
        intervene: {},
        clock: {},
        job: {},
        order: {},
        info: {},
        apply: [],
      },
      settleType: {
        1:'日结',
        2:'项目结',
        3:'可议'
      },
      status:{
        0:'待确认',
        1:'待确认',
        2:'待开工',
        3:'待完工',
        4:'待结算',
        5:'已完成'
      },
      applyCancel:{
        1:"申请退款",
        2:"申请取消"
      },
      cancel:{
        1:"老板已经取消",
        2:"师傅已经取消"
      },
      labelStyle: { 'width': '160px' },
      applyNum: 0,
    }
  },
  methods: {
    // 获取详情
    async getDetail(row, orderType) {
      this.orderType = orderType; // 状态正在招和已结束查招工详情，其他状态查工单详情
      this.isChange = true;
      if(orderType == 1) { // 工单详情
        await this.$http.get('/user/flex_order/info', {params:{id: row.id} }).then(res => {
          if(res.code === 1) {
            this.dataInfo.cancel = res.data.cancel;
            this.dataInfo.intervene = res.data.intervene;
            this.dataInfo.clock = res.data.clock;
            this.dataInfo.job = res.data.job;
            this.dataInfo.order = res.data.order;
            this.dataInfo.happen_at = this.$moment(res.data.created_at).format('YYYY-MM-DD')
          } else {
            this.$message.error(res.msg)
          }
        })
      } else { // 招工详情
        await this.$http.get('/user/flex_job/info', {params:{id: row.id} }).then(res => {
          if(res.code === 1) {
            this.dataInfo.apply = res.data.apply;
            this.dataInfo.info = res.data.info;
            this.applyNum = res.data.apply ? res.data.apply.length : 0
          } else {
            this.$message.error(res.msg)
          }
        })
      }
    },
    reset(done) {
      this.isChange = false
      // this.$refs.elFormDom.resetFields()
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
    toStr(data, key) {
      return data[key]
    }
  }
}
</script>

<style scoped>
.info-top{
  display: flex;
  justify-content: start;
  align-items: center;
}
.portrait {
text-align: center;
margin-right: 10px;
margin-top: 10px;
color: #606266;
}
.portrait ::v-deep .el-avatar--large {
  width: 50px;
  height: 50px;
  line-height: 40px;
}
</style>